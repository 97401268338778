'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function Background() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'background',
        type: 'background',
        layout: {
          visibility: 'visible',
        },
        paint: {
          'background-color': cn({
            '#fafaf8': config.baseTheme === 'light',
            '#0e0e0e': config.baseTheme === 'dark',
          }),
          'background-opacity': 1,
        },
      }}
    />
  );
}
