'use client';

import maplibreGl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { forwardRef } from 'react';
import {
  Map as MapGL,
  type MapProps,
  type MapRef,
  type MapStyle,
} from 'react-map-gl';
import { FONT_URL, TILESERVER_URL } from '../constants';
import { DEFAULT_THEME, MapColorTheme } from '../context';
import { MapContext } from '../context/map-context';
import { AerowayLayers } from '../layers/osm/aeroway';
import { Background } from '../layers/osm/background';
import { BoundaryCountryLayers } from '../layers/osm/boundary-country';
import { BoundaryCounty } from '../layers/osm/boundary-county';
import { BoundaryState } from '../layers/osm/boundary-state';
import { BridgeLayers } from '../layers/osm/bridge';
import { BuildingLayers } from '../layers/osm/building';
import { PlaceLayers } from '../layers/osm/place';
import { POILayers } from '../layers/osm/poi';
import { RailLayers } from '../layers/osm/rail';
import { RoadLayers } from '../layers/osm/road';
import {
  RoadnameMajor,
  RoadnameMinor,
  RoadnamePri,
  RoadnameSec,
} from '../layers/osm/roadname';
import { TunnelLayers } from '../layers/osm/tunnel';
import { WaterLayers } from '../layers/osm/water';
import { WaternameLayers } from '../layers/osm/watername';
import { Waterway } from '../layers/osm/waterway';
import { WaterwayLabel } from '../layers/osm/waterway-label';
import { Placeholder } from '../layers/placeholder';
import { Satellite } from '../layers/satellite/satellite';

const baseMapStyle: MapStyle = {
  version: 8,
  sources: {
    openmaptiles: {
      type: 'vector',
      url: `${TILESERVER_URL}/planet/style.json`,
    },
  },
  glyphs: FONT_URL,
  layers: [],
};
type BaseMapProps = MapProps & {
  /**
   * StreetFerret tile server token. Must be provided if loading user tiles. See @streetferret/tile-server for generating tokens
   */
  token?: string;
  baseTheme: 'light' | 'dark';
  /**
   * Theme for the map. If not provided, the default theme will be used.
   */
  theme?: MapColorTheme;
  type: 'base' | 'satellite';
};

/**
 * React-map-gl wrapper with custom layers
 */
const BaseMap = forwardRef<MapRef, BaseMapProps>(
  ({ token, theme, type, baseTheme, children, mapStyle, ...props }, ref) => {
    return (
      <MapGL
        ref={ref}
        // @ts-expect-error - this looks to be a mismatch between mapbox and maplibre
        mapLib={maplibreGl}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
          zIndex: 0,
        }}
        attributionControl={true}
        mapStyle={mapStyle ?? baseMapStyle}
        transformRequest={
          token
            ? (url, resourceType) => {
                if (
                  (resourceType === 'Source' || resourceType === 'Tile') &&
                  url.startsWith(`${TILESERVER_URL}/user`)
                ) {
                  return {
                    url,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  };
                }
                return {
                  url,
                };
              }
            : undefined
        }
        {...props}>
        <MapContext.Provider
          value={{ theme: theme ?? DEFAULT_THEME, type, baseTheme }}>
          {mapStyle ? (
            // only render the placeholder if we have a custom map style
            <>
              <Placeholder id="_road_overlays" />
            </>
          ) : (
            <>
              <Background />
              <Waterway />
              <Placeholder id="_satellite" />
              <BoundaryCounty />
              <BoundaryState />
              <WaterLayers />
              <AerowayLayers />
              <WaterwayLabel />
              <TunnelLayers />
              <RoadLayers />
              <RailLayers />
              <BridgeLayers />
              <BuildingLayers />
              <BoundaryCountryLayers />
              <WaternameLayers />
              <Placeholder id="_road_overlays" />
              <PlaceLayers />
              <POILayers />
              <RoadnameMinor />
              <RoadnameSec />
              <RoadnamePri />
              <RoadnameMajor />
              {type === 'satellite' && <Satellite beforeId="_satellite" />}
            </>
          )}
          {children}
        </MapContext.Provider>
      </MapGL>
    );
  },
);
BaseMap.displayName = 'BaseMap';
export { BaseMap };
