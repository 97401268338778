import { MapColorTheme } from './map-theme';

export const DEFAULT_THEME = {
  name: 'Default',
  complete: {
    light: 'hsl(205, 100%, 45%)', // Slightly adjusted blue for better contrast
    dark: 'hsl(205, 100%, 45%)', // Slightly adjusted blue for better contrast
  },
  complete_selected: {
    light: 'hsl(225, 90%, 35%)', // Slightly darker blue for selected state
    dark: 'hsl(205, 80%, 65%)', // Slightly darker blue for selected state
  },
  incomplete: {
    light: 'hsl(0, 75%, 35%)', // Slightly darker red for improved contrast
    dark: 'hsl(0, 75%, 35%)', // Slightly darker red for improved contrast
  },
  incomplete_selected: {
    dark: 'hsl(0, 60%, 45%)', // Slightly darker red for selected state
    light: 'hsl(20, 90%, 25%)', // Slightly darker red for selected state
  },
  bounds: {
    light: '#000000',
    dark: '#fff',
  },
  hover: {
    light: '#000000',
    dark: '#ffffff',
  },
} as const satisfies MapColorTheme;
export const COLORBLIND_THEME = {
  name: 'Colorblind Friendly',
  complete: DEFAULT_THEME.complete,
  complete_selected: DEFAULT_THEME.complete_selected,
  incomplete: DEFAULT_THEME.incomplete,
  incomplete_selected: DEFAULT_THEME.incomplete_selected,
  hover: DEFAULT_THEME.hover,
  bounds: {
    light: 'hsl(40, 100%, 45%)', // Adjusted orange for better distinction
    dark: 'hsl(40, 100%, 45%)', // Adjusted orange for better distinction
  },
} as const satisfies MapColorTheme;

export const AllThemes = [DEFAULT_THEME, COLORBLIND_THEME] as const;
export const ThemeNames = AllThemes.map(t => t.name);
export type ThemeName = (typeof ThemeNames)[number];
