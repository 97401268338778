'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function Rail() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'rail',
        type: 'line',
        source: 'openmaptiles',
        'source-layer': 'transportation',
        minzoom: 13,
        filter: ['all', ['==', 'class', 'rail'], ['!=', 'brunnel', 'tunnel']],
        layout: {
          visibility: 'visible',
          'line-join': 'round',
        },
        paint: {
          'line-color': cn({
            '#dddddd': config.baseTheme === 'light',
            '#1a1a1a': config.baseTheme === 'dark',
          }),
          'line-width': {
            base: 1.3,
            stops: [
              [13, 0.5],
              [14, 1],
              [15, 1],
              [16, 3],
              [21, 7],
            ],
          },
        },
      }}
    />
  );
}
export function RailDash() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'rail_dash',
        type: 'line',
        source: 'openmaptiles',
        'source-layer': 'transportation',
        minzoom: 15,
        filter: ['all', ['==', 'class', 'rail'], ['!=', 'brunnel', 'tunnel']],
        layout: {
          visibility: 'visible',
          'line-join': 'round',
        },
        paint: {
          'line-color': cn({
            '#ffffff': config.baseTheme === 'light',
            '#111': config.baseTheme === 'dark',
          }),
          'line-width': {
            base: 1.3,
            stops: [
              [15, 0.5],
              [16, 1],
              [20, 5],
            ],
          },
        },
      }}
    />
  );
}
export function RailLayers() {
  return (
    <>
      <Rail />
      <RailDash />
    </>
  );
}
