'use client';
import { FillLayer, Layer, LineLayer, SymbolLayer } from 'react-map-gl';
import { useMapTheme } from '../../context';
import { useMemo } from 'react';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';
import { Expression } from 'mapbox-gl';

interface BoundaryLayersProps {
  hoveredCityId: number | null;
  selectedCities:
    | {
        cityId: number;
        name: string;
      }[]
    | null;
}
export function BoundaryLayers({
  hoveredCityId,
  selectedCities,
}: BoundaryLayersProps) {
  const { theme, baseTheme } = useMapTheme();
  const isDark = baseTheme === 'dark';
  const boundsLayer: LineLayer = useMemo(() => {
    return {
      id: 'city-bounds',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'line',
      paint: {
        'line-color': [
          'step',
          ['zoom'],
          'black', // Default color for zoom levels less than 10
          10,
          isDark ? '#FFFFFF' : '#111111', // Change at zoom level 10
        ],
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          9,
          isDark ? 0.3 : 1, // At zoom level 9, line width is 1
          11,
          1, // At zoom level 11, line width is 2
        ],
      },
      filter: selectedCitiesFilter(selectedCities),
      minzoom: 7,
    };
  }, [isDark]);
  const boundaryLabel: SymbolLayer = useMemo(() => {
    return {
      id: 'boundary-label',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'symbol',
      layout: {
        'text-font': ['Boundary-Label'],
        'text-max-angle': 15,
        'text-field': cityNameExpr(selectedCities),
        'symbol-placement': 'line',
        'text-anchor': 'center',
        'text-transform': 'uppercase',
        'text-keep-upright': true,
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          10, // Smaller text at lower zoom levels
          14,
          14, // Larger text at higher zoom levels
        ],
        'text-offset': [0, 0.8], // Offset text inside the line
      },
      paint: {
        'text-color': isDark ? '#FFFFFF' : '#111111',
      },
      filter: selectedCitiesFilter(selectedCities),
    };
  }, [isDark, selectedCities]);
  const darkRainbow = useMemo(
    () => [
      [0.0, `#4B7A7E`], // Medium Desaturated Blue
      [0.33, `#6AA1A3`], // Light Desaturated Blue
      [0.66, `#00FFFF`], // Light Cyan (closest to Light Green transformation)
      [1, `#7DF9FF`], // Electric Blue
    ],
    [],
  );
  const lightRainbow = useMemo(
    () => [
      [0.0, `#3C6A6D`], // Slightly darker blue for better contrast
      [0.33, `#579092`], // Adjusted light desaturated blue for better contrast
      [0.66, `#00E0E0`], // Slightly darker cyan for better visibility
      [1, `#6DE8F0`], // Adjusted electric blue for a softer appearance
    ],
    [],
  );
  const boundsFillLayer: FillLayer = useMemo(() => {
    return {
      id: 'city-bounds-fill',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'fill',
      maxzoom: 10,
      paint: {
        'fill-color': {
          property: 'runComplete',
          type: 'interval',
          stops: isDark ? darkRainbow : lightRainbow,
        },
      },
      filter: selectedCitiesFilter(selectedCities),
    };
  }, [darkRainbow, lightRainbow, isDark]);
  const boundsClickLayer: FillLayer = useMemo(() => {
    return {
      id: 'city-bounds-fill-click',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'fill',
      minzoom: 10,
      paint: {
        'fill-color': 'rgba(255, 255, 255, 0.0)', // White with 0% opacity
      },
      filter: selectedCitiesFilter(selectedCities),
    };
  }, [darkRainbow, lightRainbow, isDark]);
  const boundsHighlightLayer: FillLayer = useMemo(() => {
    return {
      id: 'city-bounds-highlight',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'fill',
      maxzoom: 11,
      paint: {
        'fill-opacity': [
          'interpolate',
          ['linear'],
          ['get', 'runComplete'],
          7,
          0.2,
          9,
          0.5,
        ],
        'fill-color': theme.bounds,
      },
      filter: ['==', 'cityID', hoveredCityId ?? -1],
    };
  }, [theme, hoveredCityId]);
  const selectedBoundsLayer: LineLayer = useMemo(() => {
    return {
      id: 'selected-bounds-layer',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'boundary',
      type: 'line',
      paint: {
        'line-color': theme.bounds,
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          9,
          2, // At zoom level 9, line width is 2
          11,
          3, // At zoom level 11, line width is 3
        ],
      },
      filter: selectedCitiesFilter(selectedCities),
    };
  }, [theme, selectedCities]);

  return (
    <>
      <Layer beforeId="_road_overlays" {...boundsClickLayer} />
      <Layer beforeId="_road_overlays" {...boundsFillLayer} />
      <Layer beforeId="_road_overlays" {...boundsHighlightLayer} />
      <Layer beforeId="_road_overlays" {...selectedBoundsLayer} />
      <Layer beforeId="_road_overlays" {...boundsLayer} />
      <Layer beforeId="_road_overlays" {...boundaryLabel} />
    </>
  );
}

function selectedCitiesFilter(
  selectedCities: { cityId: number; name: string }[] | null,
): Expression {
  if (selectedCities == null) {
    return ['all']; //Show all cities
  }

  return [
    'in',
    ['get', 'cityID'],
    ['literal', selectedCities.map(city => city.cityId)],
  ];
}

function cityNameExpr(
  selectedCities?: { cityId: number; name: string }[] | null,
): Expression {
  if (!selectedCities || selectedCities.length === 0) {
    return ['literal', '']; // Return default if no cities are provided
  }

  const caseBlock: Expression = ['case'];

  selectedCities.forEach(city => {
    caseBlock.push(['==', ['get', 'cityID'], city.cityId], city.name);
  });

  caseBlock.push(''); // Fallback string if no match
  return caseBlock;
}
