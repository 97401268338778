'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function BoundaryCounty() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'boundary_county',
        type: 'line',
        source: 'openmaptiles',
        'source-layer': 'boundary',
        minzoom: 9,
        maxzoom: 24,
        filter: ['all', ['==', 'admin_level', 6], ['==', 'maritime', 0]],
        paint: {
          'line-color': {
            stops: [
              [
                4,
                cn({
                  '#ead5d7': config.baseTheme === 'light',
                  '#222': config.baseTheme === 'dark',
                }),
              ],
              [
                5,
                cn({
                  '#ead5d7': config.baseTheme === 'light',
                  '#222': config.baseTheme === 'dark',
                }),
              ],
              [
                6,
                cn({
                  '#e1c5c7': config.baseTheme === 'light',
                  '#2C353C': config.baseTheme === 'dark',
                }),
              ],
            ],
          },
          'line-width': {
            stops: [
              [4, 0.5],
              [7, 1],
            ],
          },
        },
      }}
    />
  );
}
