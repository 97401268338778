'use client';
import { Layer, LineLayer } from 'react-map-gl';
import { lineWidthScale } from '../../utils/layer-utils';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';
import { useMemo } from 'react';
import { useMapTheme } from '../../context';

interface RouteLayersProps {
  hoveredRouteId: number | null;
  selectedRouteId?: number | null;
}
export function RouteLayers({
  hoveredRouteId,
  selectedRouteId,
}: RouteLayersProps) {
  const { theme } = useMapTheme();
  const routeBaseLayer: LineLayer = useMemo(() => {
    return {
      id: 'route-base',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'route',
      type: 'line',
      paint: {
        'line-color': `hsl(24.6, 95%, 53.1%)`,
        'line-width': lineWidthScale(1.6),
      },
    };
  }, []);
  const routeHoverLayer: LineLayer = useMemo(() => {
    return {
      id: 'route-hover',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'route',
      type: 'line',
      paint: {
        'line-color': `hsl(24.6, 95%, 80.1%)`,
        'line-width': lineWidthScale(1.8),
      },
      filter: ['==', 'streetID', hoveredRouteId ?? -1],
    };
  }, [hoveredRouteId]);
  const routeSelectedLayer: LineLayer = useMemo(() => {
    return {
      id: 'route-selected',
      source: USER_TILES_SOURCE_ID,
      'source-layer': 'route',
      type: 'line',
      paint: {
        'line-width': lineWidthScale(2.5),
        'line-color': theme.bounds,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      filter: ['==', 'streetID', selectedRouteId ?? -1],
    };
  }, [theme, selectedRouteId]);

  return (
    <>
      <Layer {...routeBaseLayer} beforeId="_road_overlays" />
      <Layer {...routeHoverLayer} beforeId="_road_overlays" />
      <Layer {...routeSelectedLayer} beforeId="_road_overlays" />
    </>
  );
}
