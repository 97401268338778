'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function WaterLayers() {
  const config = useMapTheme();
  return (
    <>
      <Layer
        {...{
          id: 'water',
          type: 'fill',
          source: 'openmaptiles',
          'source-layer': 'water',
          minzoom: 0,
          maxzoom: 24,
          filter: ['all', ['==', '$type', 'Polygon']],
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': cn({
              '#d4dadc': config.baseTheme === 'light',
              '#2C353C': config.baseTheme === 'dark',
            }),
            'fill-antialias': true,
            'fill-translate-anchor': 'map',
            'fill-opacity': 1,
          },
        }}
      />
      <Layer
        {...{
          id: 'water_shadow',
          type: 'fill',
          source: 'openmaptiles',
          'source-layer': 'water',
          minzoom: 0,
          filter: ['all', ['==', '$type', 'Polygon']],
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-color': 'transparent',
            'fill-antialias': true,
            'fill-translate-anchor': 'map',
            'fill-opacity': 1,
          },
        }}
      />
    </>
  );
}
