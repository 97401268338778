'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function Waterway() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'waterway',
        type: 'line',
        source: 'openmaptiles',
        'source-layer': 'waterway',
        paint: {
          'line-color': cn({
            '#d1dbdf': config.baseTheme === 'light',
            'rgba(63, 90, 109, 1)': config.baseTheme === 'dark',
          }),
          'line-width': {
            stops: [
              [8, 0.5],
              [9, 1],
              [15, 2],
              [16, 3],
            ],
          },
        },
      }}
    />
  );
}
