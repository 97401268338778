'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function BridgeLayers() {
  const config = useMapTheme();
  return (
    <>
      <Layer
        {...{
          id: 'bridge_service_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 15,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'service'],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [15, 1],
                [16, 3],
                [17, 6],
                [18, 8],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#ddd': config.baseTheme === 'light',
              '#1c1c1c': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_minor_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 13,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'minor'],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'miter',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 0.5],
                [12, 0.5],
                [14, 2],
                [15, 3],
                [16, 4.3],
                [17, 10],
                [18, 14],
              ],
            },
            'line-opacity': 1,
            'line-color': {
              stops: [
                [
                  13,
                  cn({
                    '#e6e6e6': config.baseTheme === 'light',
                    '#161616': config.baseTheme === 'dark',
                  }),
                ],
                [
                  15.7,
                  cn({
                    '#e6e6e6': config.baseTheme === 'light',
                    '#161616': config.baseTheme === 'dark',
                  }),
                ],
                [
                  16,
                  cn({
                    '#ddd': config.baseTheme === 'light',
                    '#1c1c1c': config.baseTheme === 'dark',
                  }),
                ],
              ],
            },
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_sec_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 11,
          maxzoom: 24,
          filter: [
            'all',
            ['in', 'class', 'secondary', 'tertiary'],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'miter',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 0.5],
                [12, 1.5],
                [13, 3],
                [14, 5],
                [15, 6],
                [16, 8],
                [17, 12],
                [18, 16],
              ],
            },
            'line-opacity': 1,
            'line-color': {
              stops: [
                [
                  11,
                  cn({
                    '#e6e6e6': config.baseTheme === 'light',
                    '#1a1a1a': config.baseTheme === 'dark',
                  }),
                ],
                [
                  12.99,
                  cn({
                    '#e6e6e6': config.baseTheme === 'light',
                    '#1a1a1a': config.baseTheme === 'dark',
                  }),
                ],
                [
                  13,
                  cn({
                    '#ddd': config.baseTheme === 'light',
                    '#232323': config.baseTheme === 'dark',
                  }),
                ],
              ],
            },
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_pri_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 8,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'primary'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [6, 0.5],
                [7, 0.8],
                [8, 1],
                [11, 3],
                [13, 4],
                [14, 6],
                [15, 8],
                [16, 10],
                [17, 14],
                [18, 18],
              ],
            },
            'line-opacity': {
              stops: [
                [5, 0.5],
                [7, 1],
              ],
            },
            'line-color': {
              stops: [
                [
                  8,
                  cn({
                    '#e6e6e6': config.baseTheme === 'light',
                    '#1a1a1a': config.baseTheme === 'dark',
                  }),
                ],
                [
                  12,
                  cn({
                    '#ddd': config.baseTheme === 'light',
                    '#232323': config.baseTheme === 'dark',
                  }),
                ],
              ],
            },
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_trunk_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 5,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'trunk'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
            visibility: 'visible',
          },
          paint: {
            'line-width': {
              stops: [
                [6, 0.5],
                [7, 0.8],
                [8, 1],
                [11, 3],
                [13, 4],
                [14, 6],
                [15, 8],
                [16, 10],
                [17, 14],
                [18, 18],
              ],
            },
            'line-opacity': {
              stops: [
                [5, 0.5],
                [7, 1],
              ],
            },
            'line-color': {
              stops: [
                [
                  5,
                  cn({
                    '#e6e6e6': config.baseTheme === 'light',
                    '#1a1a1a': config.baseTheme === 'dark',
                  }),
                ],
                [
                  12,
                  cn({
                    '#ddd': config.baseTheme === 'light',
                    '#232323': config.baseTheme === 'dark',
                  }),
                ],
              ],
            },
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_mot_case',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 5,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'motorway'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [6, 0.5],
                [7, 0.8],
                [8, 1],
                [11, 3],
                [12, 4],
                [13, 5],
                [14, 7],
                [15, 9],
                [16, 11],
                [17, 13],
                [18, 22],
              ],
            },
            'line-opacity': {
              stops: [
                [6, 0.5],
                [7, 1],
              ],
            },
            'line-color': {
              stops: [
                [
                  5,
                  cn({
                    '#e6e6e6': config.baseTheme === 'light',
                    '#1a1a1a': config.baseTheme === 'dark',
                  }),
                ],
                [
                  10,
                  cn({
                    '#ddd': config.baseTheme === 'light',
                    '#232323': config.baseTheme === 'dark',
                  }),
                ],
              ],
            },
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_path',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 15,
          maxzoom: 24,
          filter: ['all', ['==', 'class', 'path'], ['==', 'brunnel', 'bridge']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [15, 0.5],
                [16, 1],
                [18, 3],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#d5d5d5': config.baseTheme === 'light',
              '#262626': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_service_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 15,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'service'],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [15, 2],
                [16, 2],
                [17, 4],
                [18, 6],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#fdfdfd': config.baseTheme === 'light',
              '#0b0b0b': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_minor_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 15,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'minor'],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [15, 3],
                [16, 4],
                [17, 8],
                [18, 12],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#fdfdfd': config.baseTheme === 'light',
              '#0b0b0b': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_sec_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 13,
          maxzoom: 24,
          filter: [
            'all',
            ['in', 'class', 'secondary', 'tertiary'],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 2],
                [13, 2],
                [14, 3],
                [15, 4],
                [16, 6],
                [17, 10],
                [18, 14],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#fff': config.baseTheme === 'light',
              '#0b0b0b': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_pri_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 11,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'primary'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 1],
                [13, 2],
                [14, 4],
                [15, 6],
                [16, 8],
                [17, 12],
                [18, 16],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#fff': config.baseTheme === 'light',
              '#0b0b0b': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_trunk_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 11,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'trunk'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
            visibility: 'visible',
          },
          paint: {
            'line-width': {
              stops: [
                [11, 1],
                [13, 2],
                [14, 4],
                [15, 6],
                [16, 8],
                [17, 12],
                [18, 16],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#fff': config.baseTheme === 'light',
              'rgba(65, 71, 88, 1)': config.baseTheme === 'dark',
            }),
          },
        }}
      />
      <Layer
        {...{
          id: 'bridge_mot_fill',
          type: 'line',
          source: 'openmaptiles',
          'source-layer': 'transportation',
          minzoom: 10,
          maxzoom: 24,
          filter: [
            'all',
            ['==', 'class', 'motorway'],
            ['!=', 'ramp', 1],
            ['==', 'brunnel', 'bridge'],
          ],
          layout: {
            'line-cap': 'butt',
            'line-join': 'round',
          },
          paint: {
            'line-width': {
              stops: [
                [10, 1],
                [12, 2],
                [13, 3],
                [14, 5],
                [15, 7],
                [16, 9],
                [17, 11],
                [18, 20],
              ],
            },
            'line-opacity': 1,
            'line-color': cn({
              '#fff': config.baseTheme === 'light',
              'rgba(65, 71, 88, 1)': config.baseTheme === 'dark',
            }),
          },
        }}
      />
    </>
  );
}
