'use client';

import { Layer } from 'react-map-gl';
import { useMemo } from 'react';
import { type ActivityType } from '@streetferret/api';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';
import { lineWidthScale } from '../../utils/layer-utils';
import { useMapTheme } from '../../context/map-context';

export type ProgressLayerFilterState =
  | 'all'
  | 'complete'
  | 'incomplete'
  | 'none';

const ATTR_COMPLETE = ['==', ['get', 'complete']];

export interface ProgressLayersProps {
  filterState: ProgressLayerFilterState;
  activityType: ActivityType;
  selectedStreetIds?: string[] | null;
}
export function ProgressLayers({
  activityType,
  filterState,
  selectedStreetIds,
}: ProgressLayersProps) {
  const { theme } = useMapTheme();
  const isRunType = useMemo(() => activityType === 'run', [activityType]);
  // const attrComplete = useMemo(() => ['==', ['get', 'complete']], []);

  const filterShowComplete = useMemo(
    () => ['any', [...ATTR_COMPLETE, isRunType ? 1 : 2], [...ATTR_COMPLETE, 3]],
    [isRunType],
  );
  const filterShowIncomplete = useMemo(
    () => ['any', [...ATTR_COMPLETE, 0], [...ATTR_COMPLETE, isRunType ? 2 : 1]],
    [isRunType],
  );
  const filterShowNothing = useMemo(() => ['literal', false], []);

  const completeFilter = useMemo(() => {
    if (filterState === 'all' || filterState === 'complete') {
      return filterShowComplete;
    }
    return filterShowNothing;
  }, [filterState, filterShowComplete, filterShowNothing]);

  const incompleteFilter = useMemo(() => {
    if (filterState === 'all' || filterState === 'incomplete') {
      return filterShowIncomplete;
    }
    return filterShowNothing;
  }, [filterState, filterShowIncomplete, filterShowNothing]);

  const filterShowSelected = useMemo(
    () => ['in', ['get', 'id'], ['literal', selectedStreetIds ?? []]],
    [selectedStreetIds],
  );

  return (
    <>
      {/* Incomplete and complete progress are deliberately separate layers 
      so that the incomplete is always on top where they overlap. */}
      <Layer
        id="progress-incomplete"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        minzoom={10}
        filter={incompleteFilter}
        paint={{
          'line-width': lineWidthScale(1.3),
          'line-color': theme.incomplete,
          'line-opacity': 0.6,
        }}
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        beforeId="_road_overlays"
      />
      <Layer
        id="progress-complete"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        minzoom={10}
        filter={completeFilter}
        paint={{
          'line-width': lineWidthScale(1.3),
          'line-color': theme.complete,
          'line-opacity': 0.6,
        }}
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        beforeId="_road_overlays"
      />
      <Layer
        id="selected-outer"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        paint={{
          'line-width': lineWidthScale(12),
          'line-color': '#e1ad01',
        }}
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        filter={filterShowSelected}
      />

      <Layer
        id="selected-inner"
        source={USER_TILES_SOURCE_ID}
        source-layer="progress"
        type="line"
        paint={{
          'line-width': lineWidthScale(2.5),
          'line-color': [
            'case',
            filterShowComplete,
            theme.complete_selected,
            theme.incomplete_selected,
          ],
        }}
        layout={{
          'line-join': 'round',
          'line-cap': 'round',
        }}
        filter={filterShowSelected}
      />
    </>
  );
}
