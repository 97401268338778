'use client';
import { useMapTheme } from '../../context/map-context';
import { cn } from '@streetferret/utils';
import { Layer } from 'react-map-gl';

export function Building() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'building',
        type: 'fill',
        source: 'openmaptiles',
        'source-layer': 'building',
        layout: {
          visibility: config.type === 'satellite' ? 'none' : 'visible',
        },
        paint: {
          'fill-color': {
            base: 1,
            stops: [
              [
                15.5,
                cn({
                  '#dfdfdf': config.baseTheme === 'light',
                  transparent: config.baseTheme === 'dark',
                }),
              ],
              [
                16,
                cn({
                  '#dfdfdf': config.baseTheme === 'light',
                  transparent: config.baseTheme === 'dark',
                }),
              ],
            ],
          },
          'fill-antialias': true,
        },
      }}
    />
  );
}
export function BuildingTop() {
  const config = useMapTheme();
  return (
    <Layer
      {...{
        id: 'building-top',
        type: 'fill',
        source: 'openmaptiles',
        'source-layer': 'building',
        layout: {
          visibility: config.type === 'satellite' ? 'none' : 'visible',
        },
        paint: {
          'fill-outline-color': cn({
            '#dfdfdf': config.baseTheme === 'light',
            '#0e0e0e': config.baseTheme === 'dark',
          }),
          'fill-color': cn({
            '#ededed': config.baseTheme === 'light',
            'rgba(57, 57, 57, 1)': config.baseTheme === 'dark',
          }),
          'fill-opacity': {
            base: 1,
            stops: [
              [13, 0],
              [16, 1],
            ],
          },
        },
      }}
    />
  );
}

export function BuildingLayers() {
  return (
    <>
      <Building />
      <BuildingTop />
    </>
  );
}
